
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonToggle from "@/components/common/Toggle.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonToggle,
    CommonButton,
  }
})
export default class Row extends Mixins(mixinRenewStdincome) {
  @Prop() renew_stdincome_master;

  get employee() {
    return this.yearly.employee_hash[this.renew_stdincome_master.employee_id];
  }
  get branch() {
    return this.global.branch_all_hash[this.employee.branch_id];
  } 
  

  get is_valid_renew_stdincome() {
    return this.renew_stdincome_master.is_valid == 1;
  }
  set is_valid_renew_stdincome(val) {
    this.m.update({name: 'renew_stdincome_master_valid', args: {renew_stdincome_id: this.renew_stdincome_master.renew_stdincome_id, employee_id: this.renew_stdincome_master.employee_id, is_valid: val}});
  }

  reflect() {
    this.m.update({name: 'renew_stdincome_reflect', args: {renew_stdincome_id: this.renew_stdincome_master.renew_stdincome_id, employee_id: this.renew_stdincome_master.employee_id}});
  }
  unreflect() {
    this.m.update({name: 'renew_stdincome_unreflect', args: {renew_stdincome_id: this.renew_stdincome_master.renew_stdincome_id, employee_id: this.renew_stdincome_master.employee_id}});
  }
}
