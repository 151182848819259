import { Component, Vue, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import tableMixins from '@/tablemixins';
import {makeTableMixin, dataDescript, validateDescript, latestDescript} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import * as util from "@/util";

const tableDescriptor = {
  manager: vuex_manager.renew_stdincome_manager,
  data: {
    source: 'yearly',
    key: 'renew_stdincome_masters',
    sort_key: [
      {key: 'status', keys: ['data', 'is_valid']},
      {key: 'code', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'code']},
      {key: 'social_ins_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'social_ins_id']},
      {key: 'name', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'kana']},
      {key: 'hiring_type', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'hiring_type']},
      {key: 'branch_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'branch_id']},
      {key: 'is_reflected', keys: ['data', 'is_reflected']},
    ],
    default_sort_key: [
      {key: 'is_valid', keys: ['data', 'is_valid'], sort_mode: 'desc'},
      {key: 'social_ins_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'social_ins_id'], sort_mode: 'asc'}
    ],
    search_targets: [
      {type: 'normal', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'code']},
      {type: 'normal', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'name']}, 
      {type: 'kana', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'kana']},
    ],
  },
}

const globalDescriptor = {
  name: 'global',
  vuex: vuex_data.renew_stdincome_global,
  indexers: ['renew_stdincome_id'],
  fetch(self) {
    return {};
  },
  dependencies: [], 
};
const yearlyDescriptor = {
  name: 'yearly',
  vuex: vuex_data.renew_stdincome_yearly,
  indexers: ['renew_stdincome_id', 'branch_id'],//ここのindexは、this.で参照できるようなものでなくてはならない branch_idだとthis.で参照できないからviewing_branchにした
  fetch(self) {
    return { renew_stdincome_id: self.renew_stdincome_id, branch_id: self.branch_id };
  },
  dependencies: ['global'],
};



@Component
export default class mixinStdincome extends Mixins(makeTableMixin(tableDescriptor), utilMixins) {
  get global_latest() {
    return latestDescript(globalDescriptor);
  }
  get global() {
    return vuex_data.renew_stdincome_global.get(this);
  }
  get yearly() {
    return vuex_data.renew_stdincome_yearly.get(this);
  }
  


  get renew_stdincome_id() {
    if (this.param_renew_stdincome_id == 0) {
      this.param_renew_stdincome_id = this.global_latest.latest_id;
      return this.global_latest.latest_id;
    } else {
      return this.param_renew_stdincome_id;
    }
  }
  set renew_stdincome_id(val) {
    this.param_renew_stdincome_id = val;
  }
  get param_renew_stdincome_id() {
    var renew_stdincome_id = this.query.renew_stdincome_id;
    if (renew_stdincome_id === undefined) {
      renew_stdincome_id = 0;
      this.query = {renew_stdincome_id};
    }
    return renew_stdincome_id;
  }
  set param_renew_stdincome_id(val) {
    if (val != this.param_renew_stdincome_id) {
      this.query_push = {renew_stdincome_id: val};
    }
  }

  get branch_id() {
    var branch_id = this.query.viewing_branch_renew_stdincome;
    if (branch_id === undefined) {
      branch_id = 0;
      this.query = {viewing_branch_renew_stdincome: branch_id};
    }
    return branch_id;
  }
  set branch_id(val) {
    this.page = 1;
    this.query = {viewing_branch_renew_stdincome: val};
  }
  

  get editable_renew_stdincome() {
    return true;
  }
  get reflectable_renew_stdincome() {
    return true;
  }


  get is_reflect_timing() {
    return util.date2unix(this.global.renew_stdincome.year+"-09-01") <= util.date2unix(util.today());
  }
}
