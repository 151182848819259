
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonSelect,
  }
})
export default class Branchselector extends Mixins(mixinRenewStdincome) {
  get branch_hash() {
    return this.global.branch_hash;
  }
}
