
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import RenewstdincomeTableBranchselector from "@/components/renewstdincome/table/Branchselector.vue";
import RenewstdincomePagerHead from "@/components/renewstdincome/pager/Head.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    RenewstdincomeTableBranchselector,
    RenewstdincomePagerHead,
    CommonButton,
  }
})
export default class Control extends Mixins(mixinRenewStdincome) {
  created() {
    this.m.setEditFlag({name: 'renew_stdincome_masters', val: null});
  }
  erase() {
    this.m.delete({name: 'renew_stdincome', args: {renew_stdincome_id: this.renew_stdincome_id}});
  }
  edit() {
    this.m.startEdit({name: 'renew_stdincome_masters', val: true, object: this.yearly.renew_stdincome_masters, hash_key: 'employee_id'});
  }
  cancel() {
    this.m.finishEdit({name: 'renew_stdincome_masters'});
  }
  update() {
    this.m.update({name: 'renew_stdincome_masters', args: {renew_stdincome_id: this.renew_stdincome_id, renew_stdincome_masters: this.m.tmp_objects['renew_stdincome_masters']}});
  }

  reflect() {
    this.m.update({name: 'renew_stdincomes_reflect', args: {renew_stdincome_id: this.renew_stdincome_id, branch_id: this.branch_id}});
  }
  unreflect() {
    this.m.update({name: 'renew_stdincomes_unreflect', args: {renew_stdincome_id: this.renew_stdincome_id, branch_id: this.branch_id}});
  }

  csv() {
    this.m.openDialog({name: 'renew_stdincome_csv'});
  }
  pdf() {
    this.m.document({name: 'renew_stdincome_pdf', args: {renew_stdincome_id: this.renew_stdincome_id, employee_ids: this.employee_ids}});
  }

  get employee_ids() {
    var renew_stdincome_masters = util.deep_copy(this.yearly.renew_stdincome_masters);
    renew_stdincome_masters.sort((x, y) => util.sort_idx_nullfirst(this.yearly.employee_hash[x.employee_id].social_ins_id, this.yearly.employee_hash[y.employee_id].social_ins_id, 'asc'));
    return renew_stdincome_masters.map(renew_stdincome_master => renew_stdincome_master.employee_id);
  }

  async summery_csv() {
    this.m.openDialog({name: 'renew_stdincome_summery'});
  }
}
