
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonSelect from "@/components/common/Select.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonSelect,
    CommonText,
  }
})
export default class Head extends Mixins(mixinRenewStdincome) {
  disp_num_option = {3: 3, 5: 5, 10: 10, 30: 30, 50: 50, 100: 100};

  created() {
    let disp_num = util.getLocalStorage('renew_stdincome_disp_num');
    this.disp_num = disp_num ? Number(disp_num) : 10;
  }
}
