
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonCheck from "@/components/common/Check.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonYearmonth from "@/components/common/Yearmonth.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonCheck,
    CommonSelect,
    CommonYearmonth,
    CommonText,
  }
})
export default class Row extends Mixins(mixinRenewStdincome) {
  @Prop() renew_stdincome_master;

  get employee() {
    return this.yearly.employee_hash[this.renew_stdincome_master.employee_id];
  }
  get branch() {
    return this.global.branch_all_hash[this.employee.branch_id];
  }

  get tmp_renew_stdincome_master() {
    return this.m.tmp_objects.renew_stdincome_masters[this.renew_stdincome_master.employee_id];
  }
  set tmp_renew_stdincome_master(val) {
    this.m.setTmpObject({name: 'renew_stdincome_masters', key: this.renew_stdincome_master.employee_id, val: val});
  }

  input_retroactive(month) {
    if (month == 4) {
      this.tmp_renew_stdincome_master['retroactive_2'] = null;
      this.tmp_renew_stdincome_master['retroactive_3'] = null;
    } else if (month == 5) {
      this.tmp_renew_stdincome_master['retroactive_1'] = null;
      this.tmp_renew_stdincome_master['retroactive_3'] = null;
    } else if (month == 6) {
      this.tmp_renew_stdincome_master['retroactive_1'] = null;
      this.tmp_renew_stdincome_master['retroactive_2'] = null;
    }
  }

  get remove_over_seventy_month_keys() {
    if (this.tmp_renew_stdincome_master['is_over_seventy'] && this.tmp_renew_stdincome_master['over_seventy_month_2']) {
      return [this.tmp_renew_stdincome_master['over_seventy_month_2']];
    } else {
      return [];
    }
  }
  get remove_over_seventy_month_2_keys() {
    if (this.tmp_renew_stdincome_master['is_over_seventy'] && this.tmp_renew_stdincome_master['over_seventy_month']) {
      return [this.tmp_renew_stdincome_master['over_seventy_month']];
    } else {
      return [];
    }
  }












   

}
