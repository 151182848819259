
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonProgress from "@/components/common/Progress.vue";
import RenewstdincomeTableMain from "@/components/renewstdincome/table/Main.vue";

@Component({
  components: {
    CommonProgress,
    RenewstdincomeTableMain,
  }
})
export default class Main extends Mixins(mixinRenewStdincome) {
  
}
