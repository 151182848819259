
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
@Component({
  components: {
  }
})
export default class Foot extends Mixins(mixinRenewStdincome) {
  
}
