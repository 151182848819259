
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import RenewstdincomeNorenewstdincome from "@/components/renewstdincome/Norenewstdincome.vue";
import RenewstdincomeMain from "@/components/renewstdincome/Main.vue";

@Component({
  components: {
    RenewstdincomeNorenewstdincome,
    RenewstdincomeMain,
  }
})
export default class RenewStdincome extends Mixins(mixinRenewStdincome) {
}
