
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import RenewstdincomeTableInputrow from "@/components/renewstdincome/table/Inputrow.vue";
import RenewstdincomeTableRow from "@/components/renewstdincome/table/Row.vue";

@Component({
  components: {
    CommonInfobutton,
    RenewstdincomeTableInputrow,
    RenewstdincomeTableRow,
  }
})
export default class Table extends Mixins(mixinRenewStdincome) {
  get renew_stdincome_masters() {
    return this.datas.yearly.renew_stdincome_masters;
  }
}
