
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import global from "@/vuex/renew_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import RenewstdincomeTableControl from "@/components/renewstdincome/table/Control.vue";
import RenewstdincomeTableTable from "@/components/renewstdincome/table/Table.vue";
import RenewstdincomePagerFoot from "@/components/renewstdincome/pager/Foot.vue";
import RenewstdincomeDialogCsv from "@/components/renewstdincome/dialog/Csv.vue";
import RenewstdincomeDialogSummery from "@/components/renewstdincome/dialog/Summery.vue";

@Component({
  components: {
    RenewstdincomeTableControl,
    RenewstdincomeTableTable,
    RenewstdincomePagerFoot,
    RenewstdincomeDialogCsv,
    RenewstdincomeDialogSummery,
  }
})
export default class Main extends Mixins(mixinRenewStdincome) {
}
