
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    UtilPopup,
    CommonText,
  }
})
export default class Dialog extends Mixins(mixinRenewStdincome) {
  dialog_index: string = 'renew_stdincome_summery';

  created() {
    this.m.closeDialog({name: this.dialog_index});
    this.m.setTmpObjects({name: 'media_code', val: null});
  }

  async csv() {
    dialog.openProgressDialog({title: '作成中', msg: '作成に時間がかかることがあります。', detail: 'そのままお待ちください。'});
    await util.post_document('document/generate_social_ins_summery', {renew_stdincome_id: this.renew_stdincome_id, employee_ids: this.employee_ids, media_code: this.m.tmp_objects['media_code']}, '算定基礎届電子申請総括票.pdf');
    this.is_display = false;
  }

  get employee_ids() {
    return this.yearly.renew_stdincome_masters.map(renew_stdincome_master => renew_stdincome_master.employee_id);
  }

  get tmp_media_code() {
    return this.m.tmp_objects['media_code'];
  }
  set tmp_media_code(val) {
    this.m.setTmpObjects({name: 'media_code', val: val});
  }

  
  get is_display() {
    if (this.m.dialog_flag[this.dialog_index]) {
      return true;
    } else {
      return false;
    }
  }
  set is_display(val: boolean) {
    if (val) {
      this.m.openDialog({name: this.dialog_index});
    } else {
      this.m.closeDialog({name: this.dialog_index});
    }
  }
}
