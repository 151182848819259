
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinRenewStdincome from '@/mixins/mixinRenewStdincome';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/renew_stdincome/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Control extends Mixins(mixinRenewStdincome) {
  create_renew_stdincome() {
    this.m.create({name: 'renew_stdincome', args: {}});
  }
}
